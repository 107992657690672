import React, { useState, useEffect, useRef } from "react"
import Styles from "../components/target.module.scss"

type Props = {
  children: React.ReactNode
}

const TargetFadeIn: React.VFC<Props> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [classNames, setClassNames] = useState(Styles.scroll)

  useEffect(() => {
    const cb = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          //クラスの付け替え
          setClassNames(Styles.scrollIn)
          io.unobserve(entry.target)
        } else {
          //クラスの付け替え
          setClassNames(Styles.scroll)
        }
      })
    }
    const options = {
      root: null, //ビューポートと交差
      rootMargin: "-15% 0px", //上下内側に-10%の地点で処理を実行する
    }
    const io = new IntersectionObserver(cb, options)
    ref.current && io.observe(ref.current)
  }, [])
  return (
    <div ref={ref} className={classNames}>
      {children}
    </div>
  )
}

export default TargetFadeIn
