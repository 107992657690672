export const shuffleArray = <T>(array: readonly T[]): readonly T[] => {
  const newArr = Array.from(array)
  for (let i = newArr.length; 1 < i; i--) {
    const k = Math.floor(Math.random() * i)
    // 要素の入れ替え
    ;[newArr[k], newArr[i - 1]] = [newArr[i - 1], newArr[k]]
  }

  return newArr
}
