import React from "react"
import styles from "./index.module.scss"

type Props = {
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  size?: "Small" | "Medium" | "Large"
  title: string
  subTitle: string
}

export const Heading: React.VFC<Props> = ({
  level,
  title,
  subTitle,
  size = "Medium",
}) => {
  const HeadingTag = level
  const fontSize = `heading${size}`

  return (
    <HeadingTag className={[styles.heading, styles[fontSize]].join(" ")}>
      <span className={styles.subHeading}>{subTitle}</span>
      {title}
    </HeadingTag>
  )
}
