import { graphql, PageProps } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"

import { Heading } from "@/components/heading"
import { Layout } from "@/components/layout"
import SEO from "@/components/seo"

import styles from "./index.module.scss"
import { Form } from "@/components/form"
import { splitArray } from "@/utilities/split-array"
import { shuffleArray } from "@/utilities/shuffle-array"

import TargetFadeIn from "@/components/targetFadeIn"
import { allPosition, Position } from "@/constants/member"

type Member = GatsbyTypes.MemberPageQuery["allMicrocmsMembers"]["nodes"]

const MemberPage: React.VFC<PageProps<GatsbyTypes.MemberPageQuery>> = ({
  data,
}) => {
  const [filterMember, setFilterMember] = useState<Position>("ALL")
  const { nodes } = data.allMicrocmsMembers
  const [focusOnMember, setFocusOnMember] = useState("")
  const [member, setMember] = useState<Member[]>([])

  // メンバーをフォーカスまたはMO
  const handleFocusOn = useCallback(id => {
    setFocusOnMember(id)
  }, [])

  const handleFocusOut = useCallback(() => {
    setFocusOnMember("")
  }, [])

  useEffect(() => {
    // 表示するメンバー
    const membersToFilter =
      filterMember === "ALL"
        ? nodes
        : nodes.filter(({ position }) => position === filterMember)
    // 配列のシャッフル
    const shuffledData = shuffleArray(membersToFilter)
    // 配列を7個ずつ分割
    const splitData = splitArray(shuffledData, 7)
    setMember(splitData)
    // タブ切り替え
  }, [filterMember])

  // 役職切り替え
  const handlePositionClick = useCallback((position: Position): void => {
    setFilterMember(position)
  }, [])
  return (
    <>
      <SEO title="メンバー | TAM" />
      <Layout>
        <div className={styles.memberWrapper}>
          <div className={styles.member}>
            <Heading
              level="h1"
              size="Large"
              title={
                "TAM ECチームのクリエイティブ、\nテクノロジーを支えるPEOPLE。"
              }
              subTitle="メンバー"
            />
            <div className={styles.spacer} aria-hidden="true"></div>
            <div className={styles.memberBackgroundText}>
              <ul className={styles.memberCategories}>
                <li
                  className={`${styles.memberCategory} ${
                    filterMember === allPosition.all
                      ? styles.currentCategory
                      : ""
                  }`}
                >
                  <button
                    onClick={() => handlePositionClick(allPosition.all)}
                    className={styles.memberCategoryButton}
                  >
                    すべて
                  </button>
                </li>
                <li
                  className={`${styles.memberCategory} ${
                    filterMember === allPosition.director
                      ? styles.currentCategory
                      : ""
                  }`}
                >
                  <button
                    onClick={() => handlePositionClick(allPosition.director)}
                    className={styles.memberCategoryButton}
                  >
                    ディレクター
                  </button>
                </li>
                <li
                  className={`${styles.memberCategory} ${
                    filterMember === allPosition.designer
                      ? styles.currentCategory
                      : ""
                  }`}
                >
                  <button
                    onClick={() => handlePositionClick(allPosition.designer)}
                    className={styles.memberCategoryButton}
                  >
                    デザイナー
                  </button>
                </li>
                <li
                  className={`${styles.memberCategory} ${
                    filterMember === allPosition.engineer
                      ? styles.currentCategory
                      : ""
                  }`}
                >
                  <button
                    onClick={() => handlePositionClick(allPosition.engineer)}
                    className={styles.memberCategoryButton}
                  >
                    エンジニア
                  </button>
                </li>
              </ul>
            </div>
            <div className={styles.spacerLarge} aria-hidden="true"></div>
            {member.map((list, i) => (
              <ul key={i} className={styles.memberList}>
                {list.map(item => (
                  <li
                    tabIndex={0}
                    key={item.membersId}
                    className={styles.memberItem}
                    onFocus={() => handleFocusOn(item.membersId)}
                  >
                    <TargetFadeIn>
                      <div
                        className={styles.memberItemInner}
                        onMouseEnter={() => handleFocusOn(item.membersId)}
                        onMouseLeave={handleFocusOut}
                      >
                        <div className={styles.memberItemImageWrapper}>
                          <img
                            src={
                              item.membersId === focusOnMember
                                ? item.activeImage?.url
                                : item.image?.url
                            }
                            alt=""
                            width={item.image?.width}
                            height={item.image?.height}
                          />
                          <p className={styles.memberItemRole}>
                            {item.position}
                          </p>
                        </div>
                        <p className={styles.memberItemName}>{item.name}</p>
                      </div>
                    </TargetFadeIn>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <Form />
      </Layout>
    </>
  )
}

export const query = graphql`
  query MemberPage {
    allMicrocmsMembers {
      nodes {
        membersId
        name
        position
        image {
          height
          url
          width
        }
        activeImage {
          height
          url
          width
        }
      }
    }
  }
`

export default MemberPage
